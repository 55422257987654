<template>
  <div class="max-width">
    <div class="mt-7">
      <p class="font-32 font-800 darktext--text mb-0">
        
        {{ $t('homepage.countriesTitle') }}
      </p>
      <!-- <p class="font-24 darktext--text">
        {{ $t('homepage.countriesDesc') }}
        
      </p> -->
    </div>

    <v-row class="mt-7">
      
      <v-col cols="12" sm="6" md="3" v-for="(country, i) in data" :key="i">
        <div class="h-100 radius-15" :class="i % 2 == 0 ? '' : 'top-25'" >
          <country-cover :item="country"></country-cover>
        </div>
      </v-col>
    </v-row>

    <!-- show more -->
    <div class="text-center mt-14 mb-14">
      <div class="white radius-10 px-6 py-3 d-inline-block cursor-pointer" @click="moreHandler">
        <span class="font-16 blacktext--text">Show more countries</span>
      </div>
    </div>

    <!-- contact -->
    <get-in-touch></get-in-touch>
  </div>
</template>

<script>
import CountryCover from '../../components/core/CountryCover.vue';
import GetInTouch from "../../components/core/GetInTouch.vue";
export default {
  components: { GetInTouch, CountryCover },
  data: () => ({
    data: []
  }),
  methods:{
    async getData(){
      let {data} = await this.$axios.get('countries')
      if(data){
        this.data = data.data
      }
    },
    moreHandler(){
    }
  },
  async created(){
    await this.getData()
  }  
};
</script>

<style></style>
