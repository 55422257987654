<template>
  <div class="contact-section">
    <div class="line mx-auto register-primary mb-4 mt-6"></div>

    <div class="text-center mb-8">
      <span class="font-48 darktext--text font-600">{{
        $t("getInTouch.getInTouch")
      }}</span>
    </div>

    <div class="cover white pa-7 radius-10">
      <v-row>
        <v-col cols="12" md="7">
          <div class="flex-between-column h-80">
            <div>
              <span class="font-28 darktext--text font-600"
                >{{ $t("getInTouch.howCanWeHelp") }}
              </span>
            </div>
            <div>
              <p
                class="font-18 darktext--text font-300"
                style="font-weight: bold"
              >
                {{ $t("getInTouch.contactUsNow") }}

              </p>
            </div>

            <div>
              <div
                class="px-4 py-3 radius-5 lightgreen d-flex align-center pr-14 w-max mb-4 w-100"
                v-for="(info, i) in infos"
                :key="i"
              >
                <img class="mx-3" :src="info.icon" alt="" />
                <span class="font-15 darktext--text">{{ info.title }}</span>
              </div>
            </div>
          </div>
        </v-col>

        <!-- form -->
        <v-col cols="12" md="5">
          <v-form
            v-model="valid"
            ref="contactForm"
            @submit.prevent="handleSubmit"
          >
            <!-- name -->
            <v-text-field
              v-model="obj.name"
              :placeholder="$t('forms.fullName')"
              height="45px"
              dense
              outlined
              :hide-details="true"
              background-color="lightgreen"
              color="darktext"
              :rules="[(v) => !!v || $t('forms.required')]"
            ></v-text-field>

            <!-- email -->
            <v-text-field
              v-model="obj.email"
              :placeholder="$t('forms.email')"
              height="50px"
              class="mt-5"
              dense
              outlined
              :hide-details="true"
              background-color="lightgreen"
              color="darktext"
              :rules="emailRules"
            ></v-text-field>
            <!-- name -->
            <v-textarea
              v-model="obj.message"
              :placeholder="$t('forms.yourMessage')"
              dense
              rows="5"
              class="mt-5"
              no-resize
              outlined
              :hide-details="true"
              background-color="lightgreen"
              color="darktext"
              :rules="messageRules"
            ></v-textarea>

            <!-- submit btn -->
            <v-btn
              color="register-primary"
              elevation="0"
              block
              height="45px"
              class="radius-5 mt-6"
              type="submit"
            >
              <span class="white--text font-16">{{ $t("forms.send") }}</span>
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    infos: [
      {
        title: "+965 9775 4394",
        icon: require("@/assets/images/icons/phone-green.svg"),
      },
      {
        title: "info@medicaljob.me",
        icon: require("@/assets/images/icons/mail-green.svg"),
      },
      {
        title:
          "16th Floor, Panasonic Tower, Fahad Al Salem Street Kuwait City, Kuwait",
        icon: require("@/assets/images/icons/location-green.svg"),
      },
    ],
    obj: {},
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    messageRules: [
      (v) => !!v || "Message is required",
      (v) => v.length > 7 || "Message must be at least 8 charecter",
    ],
    valid: false,
  }),
  methods: {
    async handleSubmit() {
      let valid = this.$refs.contactForm.validate();
      if (!valid) {
        return;
      }

      let { data } = await this.$axios.post("contact/message", this.obj, {
        headers: {
          Accept: "application/json",
        },
      });
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Message Sent Successfully",
          color: "success",
        });
        this.$refs.contactForm.reset();
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.contact-section {
  max-width: 900px;
  margin: auto;
  margin-bottom: 17%;
  border-radius: 10px;
  .cover {
    box-shadow: 0px 41.491310119628906px 59.27330017089844px 0px #adc7ee66;
  }

  .v-input--is-focused fieldset,
  fieldset {
    border: none;
  }
}
</style>
