<template>
  <div class="country-cover h-100">
    <div class="img-filter h-100 d-flex align-center">
      <img class="object-cover" height="350px" width="100%" :src="item.image" alt="" />
    </div>
    <div class="layout"></div>
    <div class="country-cover__content h-100">
      <div class="relative z-9">
        <span class="font-24 font-700 white--text d-block">{{item.name}}</span>
        <!-- <span class="font-18 white--text">{{ text }}</span> -->
        <!-- <span class="font-18 white--text" v-if="text.length > 30">......</span> -->
        <v-btn
          color="register-primary"
          elevation="0"
          block
          height="50px"
          class="radius-10 mt-3"
          @click="$router.push(`/country/${item.id}`)"
        >
          <span class="white--text font-700 font-18"> {{ $t("homepage.showCountriesDetails") }}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.country-cover {
  position: relative;
  overflow: hidden;
  &__content {
    padding: 10px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: end;
    visibility: hidden;
    transition: 0.3s;
    opacity: 0;
    overflow: hidden;
    border-radius: 15px;
    z-index: 99;
  }
  .layout {
    background: rgba($register-primary, 0.8);
    mix-blend-mode: multiply;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9;
    border-radius: 15px;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
  }
  img {
    transition: 0.3s;
  }
  &:hover {
    img {
      filter: blur(4px) drop-shadow(0px 56px 80px rgba(11, 58, 50, 0.26)) !important;
    }
    .layout {
      opacity: 1;
      visibility: visible;
    }
  }
  &:hover & {
    &__content {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
